import React, { useState, useEffect, useCallback } from 'react';
import { Button, Container, Alert, Form, Row, Col, Card, CardText } from 'react-bootstrap';

function App() {
  // State 定義
  const [currentLocation, setCurrentLocation] = useState('');
  const [locationError, setLocationError] = useState('');
  const [inputText, setInputText] = useState('');
  const [coordinates, setCoordinates] = useState([]);
  const [completed, setCompleted] = useState([]);
  const [adjust, setAdjust] = useState(false);
  const [copyMessage, setCopyMessage] = useState('');
  const [showToast, setShowToast] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [completedCount, setCompletedCount] = useState(0);


  // 獲取當前位置
  const getCurrentLocation = () => {
    if (!navigator.geolocation) {
      setLocationError('Geolocation is not supported by your browser');
    } else {
      navigator.geolocation.getCurrentPosition((position) => {
        setCurrentLocation(`${position.coords.latitude}, ${position.coords.longitude}`);
        setLocationError('');
      }, () => {
        setLocationError('Unable to retrieve your location');
      });
    }
  };
  // 函數定義
  const parseCoordinates = useCallback((text) => {
    //             (\d{2}:\d{2}.+)\s(-?[0-9]{1,3}+\.[0-9]+,\s?[0-9]+\.[0-9]+)\s?(\D+)
    // const regex = /(\d{2}:\d{2})\s([^\s]+)\s?([0-9]+\.[0-9]+,\s?[0-9]+\.[0-9]+)\s?(\D+)/gm;
    const regex = /(\d{2}:\d{2})\s(.*)\s\(?([0-9]{1,3}.\d+),\s?([0-9]{1,3}.\d+)/gm;

    let match;
    const results = [];

    while ((match = regex.exec(text)) !== null) {
      const coords = match[2].split(',').map(coord => parseFloat(coord).toFixed(6)).join(',');
      const id = `${match[1].trim()}_${coords}`;
      results.push({
        id: id,
        time: match[1].trim(),
        name: match[2].trim(),
        coordinate: match[3].trim() + ', ' + match[4].trim(),
        adjusted: adjustCoordinate(coords, adjust),
        memo: match[4]
      });
    }

    setCoordinates(results);
  }, [adjust]);

  const adjustCoordinate = (coord, adjust) => {
    if (!adjust) return coord;
    return coord.split(',').map(c => {
      const [lat, lon] = c.split(',').map(Number);
      return `${(lat + 0.002951).toFixed(6)},${(lon + 0.010475).toFixed(6)}`;
    }).join(',');
  };

  // 使用 useEffect 處理 side effects
  useEffect(() => {
    getCurrentLocation();
    const savedCompleted = localStorage.getItem('completed');
    if (savedCompleted) {
      setCompleted(JSON.parse(savedCompleted));
    }

    const savedCompletedCount = localStorage.getItem('completedCount');
    if (savedCompletedCount) {
      setCompletedCount(JSON.parse(savedCompletedCount))
    }
    
  }, []);

  useEffect(() => {
    localStorage.setItem('completed', JSON.stringify(completed));
    localStorage.setItem('completedCount', JSON.stringify(completedCount));
  }, [completed, completedCount]);

  useEffect(() => {
    parseCoordinates(inputText);
  }, [inputText, parseCoordinates]);

  
  const handleCheck = (id) => {
    const completedItem = coordinates.find(coord => coord.id === id);
    if (completedItem && !completed.some(item => item.id === id)) {
      // 如果找到了该项，并且它还不在完成列表中，则添加到完成列表
      setCompleted(prevCompleted => [...prevCompleted, completedItem]);
      // 递增 completedCount 的数值
      setCompletedCount(prevCount => parseInt(prevCount, 10) + 1);
      setCoordinates(prevCoordinates => prevCoordinates.filter(coord => coord.id !== id));
    }
  };
  
  
  

  const handleClearCompleted = () => {
    setCompleted([]);
    setCompletedCount(0);
  };

  const copyToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      setCopyMessage(`Copied: ${text}`);
      setShowToast(true);
      setTimeout(() => setShowToast(false), 3000);
    } catch (err) {
      setCopyMessage(`Failed to copy: ${err}`);
      setShowToast(true);
      setTimeout(() => setShowToast(false), 3000);
    }
  };

  const removeCoordinate = (id) => {
    setCoordinates(coordinates.filter(coord => coord.id !== id));
  };
  
  const handleEdit = () => {
    setIsEditing(true);
  };
  
  const handleCountChange = (e) => {
    // 使用 parseInt 确保值被处理为整数
    setCompletedCount(parseInt(e.target.value, 10));
  };
  
  
  const handleCountSubmit = () => {
    setIsEditing(false);
    // 这里可以添加更多逻辑，例如验证输入或更新其他状态
  };

  const getCurrentTimeRange = () => {
    const currentHour = new Date().getHours();
    if (currentHour >= 2 && currentHour < 5) return { start: 2, end: 5 };
    if (currentHour >= 5 && currentHour < 8) return { start: 5, end: 8 };
    if (currentHour >= 8 && currentHour < 11) return { start: 8, end: 11 };
    if (currentHour >= 11 && currentHour < 14) return { start: 11, end: 14 };
    if (currentHour >= 14 && currentHour < 17) return { start: 14, end: 17 };
    if (currentHour >= 17 && currentHour < 20) return { start: 17, end: 20 };
    if (currentHour >= 20 && currentHour < 23) return { start: 20, end: 23 };
    return { start: 23, end: 2 }; // 23:00 至隔天 02:00
  };

  const filterCoordinatesByTimeRange = () => {
    const { start, end } = getCurrentTimeRange();
    return coordinates.filter(coord => {
      const time = parseInt(coord.time.split(':')[0]);
      if (start < end) {
        return time >= start && time < end;
      } else {
        // 跨越午夜时的情况
        return time >= start || time < end;
      }
    });
  };
  
  // UI 渲染
  return (
    <Container className="my-4">
      {/* 當前位置顯示 */}
      <div>
        <h3>Current Location</h3>
        {currentLocation ? (
          <div>
            <p>{currentLocation}</p>
            <Button onClick={() => copyToClipboard(currentLocation)}>Copy Location</Button>
          </div>
        ) : (
          <p>Fetching location...</p>
        )}
        {locationError && <Alert variant="danger">{locationError}</Alert>}
      </div>

      {/* 坐標輸入 */}
      <Form>
        <Form.Group className="mb-3">
          <Form.Control
            as="textarea"
            rows={4}
            value={inputText}
            onChange={(e) => setInputText(e.target.value)}
            placeholder="Enter text with coordinates..."
          />
        </Form.Group>
        <Form.Check 
          type="checkbox"
          label="Adjust Coordinates"
          checked={adjust}
          onChange={() => setAdjust(!adjust)}
          className="mb-3"
        />
      </Form>

      {/* 坐標列表 */}
      <h2>Coordinates List</h2>
      <Row>
        {filterCoordinatesByTimeRange().reverse().map((item) => {
          const coordinateToUse = adjust ? item.adjusted : item.coordinate;
          return (
            <Col md={4} key={item.id} className="mb-3">
              <Card onClick={() => copyToClipboard(coordinateToUse)}>
                <Card.Header>
                {item.time} {item.name}
                <Button variant="danger" className="close-button" onClick={() => removeCoordinate(item.id)}>
                  X
                </Button>
                </Card.Header>
                <Card.Body>
                  <Form.Check 
                    type="checkbox"
                    onChange={() => handleCheck(item.id)}
                    className="mr-2"
                    label={coordinateToUse}
                  />
                  {/* <CardText>{item.memo}</CardText> */}
                </Card.Body>
              </Card>
            </Col>
          );
        })}
      </Row>

      {/* 完成列表 */}
      <h2>Completed List {isEditing ? (
        <input 
          type="number" 
          value={completedCount} 
          onChange={handleCountChange}
          onBlur={handleCountSubmit}
          autoFocus
        />
      ) : (
        <span onClick={handleEdit}>{completedCount}</span>
      )} </h2>
      <Button variant="danger" onClick={handleClearCompleted}>Clear List</Button>
      {completed.map((item, index) => (
        <Alert key={index} variant="info">
          {item.time} | {item.name} |{item.coordinate}
        </Alert>
      ))}

      {/* Toast 消息 */}
      {showToast && (
        <div className="toast-container">
          <Alert variant="success">{copyMessage}</Alert>
        </div>
      )}
    </Container>
  );
}

export default App;
